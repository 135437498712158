<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-btn
        flat
        label="back"
        color="primary"
        @click="$router.push(`/statistiksiswa/${$route.params.jenjang}`)"
      ></q-btn>
      <q-separator></q-separator>
      <q-card-section class="q-px-md bg-grey-1">
        <q-markup-table flat wrap-cells class="bg-grey-1">
          <tr>
            <td>Sekolah Asal</td>
            <td>:</td>
            <td>{{ $route.params.sekolah_asal }}</td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section>
        <q-input
          outlined
          label="Cari Nama/NISN Siswa"
          v-model="searchTerm"
          @input="fetchTable"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        separator="cell"
        class="stickyTable"
        style="height: calc(100vh - 355px)"
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Nama</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in showSiswa" :key="i">
            <td>{{ (pagination.current - 1) * pagination.limit + i + 1 }}</td>
            <td>
              <div class="column">
                <a>{{ val.nama }}</a>
                <a>{{ val.nisn }}</a>
              </div>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="center">
        <q-pagination
          v-model="pagination.current"
          :max="pagination.max"
          input
          @input="fetchTable"
        />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import General from "@/mixins/General";
export default {
  mixins: [General],
  data() {
    return {
      siswa: [],
      showSiswa: [],

      searchTerm: "",

      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getData();
    this.fetchTable();
    this.$q.loading.hide();
  },
  methods: {
    async getData() {
      let resp = await this.$http.get(
        `/statistik/siswa/getlistasal/${this.$route.params.sekolah_asal}/${this.$route.params.jenjang}`
      );
      this.siswa = resp.data;
    },
    fetchTable() {
      let temp = [];

      let siswa = [];

      if (this.searchTerm == "") {
        siswa = this.siswa;
      } else {
        siswa = this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }

      for (let i = 0; i < 10; i++) {
        if (siswa[(this.pagination.current - 1) * 10 + i]) {
          temp.push(siswa[(this.pagination.current - 1) * 10 + i]);
        }
      }

      this.pagination.max = Math.ceil(siswa.length / 10);

      this.showSiswa = temp;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.scss";
</style>
